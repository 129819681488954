<template>
  <page-layout ref="layout" :show-print-option="false" class="d-print-none" @refresh="refresh">
    <template #breadcrumbs="{ year }">
      <b-breadcrumb-item :text="`Events - ${year}`" />
      <template v-if="id">
        <b-breadcrumb-item text="Students" :to="{ name: 'events-students', params: { id: null } }" />
        <b-breadcrumb-item v-if="selected.student" :text="getFullName(selected.student)" active />
      </template>
      <template v-else>
        <b-breadcrumb-item text="Students" active />
      </template>
    </template>

    <template v-if="hasChanged" #actions="{ }">
      <b-button v-b-tooltip="'Revert Changes'" variant="link" size="sm" class="btn-icon mr-50" @click="resetSelectedStudent">
        <b-icon-arrow-counterclockwise />
      </b-button>
      <can do="update" on="events-students">
        <b-button v-b-tooltip="'Update'" variant="link" size="sm" class="btn-icon" @click="updateStudent">
          <b-icon-save2 />
        </b-button>
      </can>
    </template>

    <template #dropdown-options="{ state }">
      <can do="update" on="events-students">
        <b-dropdown-item @click="state.editing = !state.editing">
          <feather-icon icon="EditIcon"/>
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>
      </can>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
      <b-dropdown-divider/>
      <b-dropdown-item @click="sidebar = !sidebar">
        <feather-icon icon="SidebarIcon"/>
        <span class="align-middle ml-50">{{ sidebar ? 'Hide' : 'Show' }} Sidebar</span>
      </b-dropdown-item>
    </template>

    <template #loading="{ state }">
      <overlay-loading :items="[
          { state: students.loading, desc: 'Loading Students'},
          { state: state.loading, desc: 'Rendering Template'},
          ]">
      </overlay-loading>
    </template>

    <template #content="{ state }">
      <event-layout :show-sidebar="sidebar" :auto-toggle-sidebar="false">
        <template #sidebar>
          <events-nav title="Students"
                      scrollbar-class="vh-max-70"
                      :selected-id="id"
                      :items="computedStudents" items-key="selection.id"
                      :filters="students.filters"
                      :sorting="students.sorting"
                      @search="(text) => students.search = text"
                      @sort="(sorting) => students.sorting = sorting"
                      @select="(item) => selectStudent(item)"
                      @deselect="deselectStudent">

            <template #item="{ item, emptyTextClass }">
              <span v-if="item.name && (item.name.first || item.name.last)">{{ getFullName(item) }}</span>
              <span v-else :class="emptyTextClass">Unnamed Student</span>
            </template>
            <template #filters>
              <v-select v-model="students.filters.busId"
                        input-id="filter-bus"
                        placeholder="Bus"
                        :options="buses.items" label="name"
                        :reduce="option => option.id"
                        :select-on-tab="true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        class="w-100 font-small-3">
                <template #option="{ name }">
                  <span :class="{'text-danger font-small-3': !name}">
                    {{ name ? name : 'Unnamed Bus' }}
                  </span>
                </template>
                <template #selected-option="{ name }">
                  <span :class="{'text-danger': !name}">
                    {{ name ? name : 'Unnamed Bus' }}
                  </span>
                </template>
              </v-select>
              <v-select v-model="students.filters.hotelId"
                        input-id="filter-hotel"
                        placeholder="Hotel"
                        :options="hotels.items" label="name"
                        :reduce="option => option.id"
                        :select-on-tab="true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        class="w-100 font-small-3 mt-2">
                <template #option="{ name }">
                  <span :class="{'text-danger font-small-3': !name}">
                    {{ name ? name : 'Unnamed Hotel' }}
                  </span>
                </template>
                <template #selected-option="{ name }">
                  <span :class="{'text-danger': !name}">
                    {{ name ? name : 'Unnamed Hotel' }}
                  </span>
                </template>
              </v-select>
              <v-select v-model="students.filters.chaperoneId"
                        input-id="filter-chaperone"
                        placeholder="Chaperone"
                        :options="chaperones.items" label="name"
                        :reduce="option => option.id"
                        :select-on-tab="true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        class="w-100 font-small-3 mt-2">
                <template #option="{ name }">
                  <span :class="{'text-ui-danger font-small-3': !name.first || !name.last}">
                    {{ name.first || name.last ? getFullName({ name: name }) : 'Unnamed Chaperone' }}
                  </span>
                </template>
                <template #selected-option="{ name }">
                  <span :class="{'text-ui-danger': !name.first || !name.last}">
                    {{ name.first || name.last ? getFullName({ name: name }) : 'Unnamed Chaperone' }}
                  </span>
                </template>
              </v-select>

              <v-select v-model="students.filters.sex"
                        input-id="filter-sex"
                        placeholder="Sex"
                        :options="options.sexes" label="text"
                        :reduce="option => option.value"
                        :select-on-tab="true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        class="w-100 font-small-3 mt-2"/>

              <v-select v-model="students.filters.gender"
                        input-id="filter-gender"
                        placeholder="Gender"
                        :options="[
                            { text: 'Conforming', value: true },
                            { text: 'Non-Conforming', value: false }
                        ]"
                        label="text"
                        :reduce="option => option.value"
                        :select-on-tab="true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        class="w-100 font-small-3 mt-2"/>

              <v-select v-model="students.filters.medicalReceived"
                        input-id="filter-medicalReceived"
                        placeholder="Receieved Medical Form"
                        :options="[
                            { text: 'Yes', value: true },
                            { text: 'No', value: false }
                        ]"
                        label="text"
                        :reduce="option => option.value"
                        :select-on-tab="true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        class="w-100 font-small-3 mt-2"/>
            </template>

            <template #options>
              <can do="dev">
                <b-dropdown-item @click="deleteStudents(computedStudents)">
                  <b-icon-trash/>
                  <span class="ml-1">Delete All Student Events</span>
                </b-dropdown-item>
              </can>
            </template>
          </events-nav>
        </template>
        <template #content>
          <template v-if="selected.loading">
            <b-card>
              <b-row>
                <b-col align-self="center" cols="auto">
                  <b-spinner variant="primary" label="Loading..."/>
                </b-col>
                <b-col align-self="center">
                  Creating Student Event Record
                </b-col>
              </b-row>
            </b-card>
          </template>
          <template v-else-if="selected.student">
            <validation-observer ref="observer" tag="div">
              <form ref="form">
                <b-row class="match-height">
                  <!-- Info -->
                  <b-col class="col-xxxl-8">
                    <b-card>
                      <b-row>
                        <!-- Avatar -->
                        <b-col align-self="start" cols="auto">
                          <b-avatar variant="primary" size="45px" badge-variant="white">
                            <font-awesome-icon :icon="icon" size="2x"></font-awesome-icon>
                          </b-avatar>
                        </b-col>
                        <!-- Details -->
                        <b-col align-self="center">
                          <!-- Details - Name -->
                          <b-row>
                            <b-col align-self="start" class="col-12 col-md-6 col-xxxl-4">
                              <validation-provider v-slot="validationContext" vid="legal-name" name="Legal Name" rules="required">
                                <b-form-group label="First Name" label-for="legal-name-input" :invalid-feedback="validationContext.errors[0]">
                                  <b-input v-model="selected.student.name.first" disabled :state="getValidationState(validationContext)"></b-input>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                            <b-col align-self="start" class="col-12 col-md-6 col-xxxl-4">
                              <!--todo - db doesn't have preferred field yet -->
                              <validation-provider v-slot="validationContext" vid="preferred-name" name="Preferred Name" rules="">
                                <b-form-group label="Preferred Name" label-for="legal-name-input" :invalid-feedback="validationContext.errors[0]">
                                  <b-input v-model="selected.student.name.preferred" :disabled="!state.editing" :state="getValidationState(validationContext)"></b-input>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                            <b-col align-self="start" class="col-12 col-xxxl-4">
                              <validation-provider v-slot="validationContext" vid="popular-name" name="Popular Name" rules="required">
                                <b-form-group label="Last Name" label-for="popular-name-input" :invalid-feedback="validationContext.errors[0]">
                                  <b-input v-model="selected.student.name.last" disabled :state="getValidationState(validationContext)"></b-input>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                          </b-row>
                          <!-- Details - Other -->
                          <b-row>
                            <b-col lg="8" md="12">
                              <validation-provider #default="{ errors }" name="School" rules="required">
                                <b-form-group label="School" label-for="school" :state="errors.length > 0 ? false:null">
                                  <v-select id="school"
                                            v-model="selected.student.school"
                                            :loading="options.schools.loading"
                                            :options="options.schools.items" label="name"
                                            :reduce="school => ({ id: school.id, name: school.name })"
                                            :select-on-tab="true"
                                            disabled
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  >
                                    <template #option="{ name }">
                                      <h6 class="mb-0">{{ name.legal }}</h6>
                                      <small class="text-muted">{{ name.popular }}</small>
                                    </template>
                                    <template #selected-option="{ name }">
                                      {{ name.legal }}
                                    </template>
                                  </v-select>
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                    {{ errors[0] }}
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                            <b-col lg="4" md="12" align-self="start">
                              <validation-provider v-slot="validationContext" vid="grade" name="Grade" rules="required">
                                <b-form-group label="Grade" label-for="grade-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                                  <v-select id="grade-input"
                                            v-model="selected.student.grade"
                                            disabled
                                            label="name"
                                            :options="options.grades"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            placeholder="Grade"
                                            class="w-100"/>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>

                  <!-- Selection -->
                  <b-col cols="12" class="col-xxxl-4">
                    <b-card no-body class="border-primary">
                      <b-card-header class="d-flex justify-content-between align-items-center pt-2 pb-25">
                        <h5 class="mb-0">
                          Selection
                        </h5>
                        <b-badge variant="light-primary">
                          Accepted
                        </b-badge>
                        <small class="text-muted w-100"><last-modified :date="selected.student.selection.updatedAt"></last-modified></small>
                      </b-card-header>
                      <b-card-body>
                        <ul class="list-unstyled my-1">
                          <li>
                            <span class="align-middle">{{ selected.student.selection.instrument.name }}</span>
                          </li>
                          <li class="my-25">
                            <span class="align-middle">{{
                                selected.student.selection.ensemble.name
                              }} - Part {{ selected.student.selection.part }}</span>
                          </li>
                          <li>
                            <span class="align-middle">{{ selected.student.selection.application.festival.zone.name }} - Festival {{
                                selected.student.selection.application.festival.name
                              }}</span>
                          </li>
                        </ul>
                      </b-card-body>
                    </b-card>
                  </b-col>
                  <!-- Alert - Unsaved -->
                  <b-col v-if="hasChanged" cols="12">
                    <b-alert show variant="primary" class="d-print-none mb-2" dismissible>
                      <b-icon-info-circle-fill font-scale="1"/> <strong class="align-middle ml-50 font-small-3">Unsaved Changes</strong>
                      <p class="align-middle font-small-3">
                        Make sure you save before switching to another student. Unsaved changes will be lost.
                      </p>
                    </b-alert>
                  </b-col>

                  <!-- Event Info -->
                  <template v-if="selected.student.event">
                    <b-col cols="12" class="col-xxl-6 col-xxxl">
                      <!-- Medical -->
                      <b-card no-body>
                        <b-card-body>
                          <b-row>
                            <b-col align-self="center" cols="auto" class="pr-50">
                              <b-avatar :variant="`light-primary`" size="45">
                                <font-awesome-icon :icon="['fas', 'fa-file-medical']" size="2x"/>
                              </b-avatar>
                            </b-col>
                            <b-col align-self="center">
                              <b-form-group label="Medical Form" label-for="room-input" class="m-0 p-0 mt-05" label-class="mb-n05 font-weight-bold">
                                <v-select id="chaperone-input"
                                          v-model="selected.student.event.medicalReceived"
                                          :disabled="!state.editing"
                                          :reduce="(option) => option.value"
                                          label="label"
                                          :options="options.checkin"
                                          :loading="false"
                                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                          :select-on-tab="true"
                                          placeholder="Not Selected"
                                          :class="['w-100', !state.editing ? 'vs--plain-text' : 'vs--plain-text']"/>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </b-card>
                    </b-col>
                    <b-col cols="12" class="col-xxl-6 col-xxxl">
                      <!-- Bus -->
                      <b-card no-body>
                        <b-card-body>
                          <b-row>
                            <b-col align-self="center" cols="auto" class="pr-50">
                              <b-avatar :variant="`light-primary`" size="45" badge-variant="secondary">
                                <font-awesome-icon icon="fa-solid fa-bus" size="2x" />
                                <template v-if="hasBusNote(selected.student.event.notes)" #badge>
                                  <b-icon-exclamation title="Has Note" class="cursor-pointer" @click="tab = 1"/>
                                </template>
                              </b-avatar>
                            </b-col>
                            <b-col align-self="center">
                              <b-form-group label="Bus" label-for="bus-input" class="m-0 p-0 mt-05" label-class="mb-n05 font-weight-bold">
                                <v-select id="bus-input"
                                          v-model="selected.student.event.busId"
                                          :disabled="!state.editing"
                                          label="name"
                                          :options="buses.items"
                                          :reduce="(option) => option.id"
                                          :loading="buses.loading"
                                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                          :select-on-tab="true"
                                          placeholder="Not Selected"
                                          :class="['w-100', !state.editing ? 'vs--plain-text' : 'vs--plain-text']">
                                  <template #option="{ name }">
                                    <span :class="{'text-danger font-small-3': !name}">
                                      {{ name ? name : 'Unnamed Bus' }}
                                    </span>
                                  </template>
                                  <template #selected-option="{ name }">
                                    <span :class="{'text-danger': !name}">
                                      {{ name ? name : 'Unnamed Bus' }}
                                    </span>
                                  </template>
                                </v-select>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </b-card>
                    </b-col>
                    <b-col cols="12" class="col-xxl-12 col-xxxl">
                      <!-- Hotel -->
                      <b-card no-body>
                        <b-card-body>
                          <b-row>
                            <b-col align-self="center" cols="auto" class="pr-50">
                              <b-avatar :variant="`light-primary`" size="45">
                                <font-awesome-icon icon="fa-solid fa-hotel" size="2x" />
                              </b-avatar>
                            </b-col>
                            <b-col align-self="center">
                              <b-form-group label="Hotel" label-for="hotel-input" class="m-0 p-0 mt-05" label-class="mb-n05 font-weight-bold">
                                <v-select id="hotel-input"
                                          v-model="selected.student.event.hotelId"
                                          label="name"
                                          :disabled="!state.editing"
                                          :options="hotels.items"
                                          :reduce="(option) => option.id"
                                          :loading="hotels.loading"
                                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                          :select-on-tab="true"
                                          placeholder="Not Selected"
                                          :class="['w-100', !state.editing ? 'vs--plain-text' : 'vs--plain-text']">
                                  <template #option="{ name }">
                                    <span :class="{'text-danger font-small-3': !name}">
                                      {{ name ? name : 'Unnamed Hotel' }}
                                    </span>
                                  </template>
                                  <template #selected-option="{ name }">
                                    <span :class="{'text-danger': !name}">
                                      {{ name ? name : 'Unnamed Hotel' }}
                                    </span>
                                  </template>
                                </v-select>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </b-card>
                    </b-col>
                    <b-col cols="12" class="col-xxl-6 col-xxxl">
                      <!-- Room -->
                      <b-card no-body>
                        <b-card-body>
                          <b-row>
                            <b-col align-self="center" cols="auto" class="pr-50">
                              <b-avatar :variant="`light-primary`" size="45">
                                <font-awesome-icon icon="fa-solid fa-bed" size="2x" />
                              </b-avatar>
                            </b-col>
                            <b-col align-self="center">
                              <b-form-group label="Room" label-for="room-input" class="m-0 p-0 mt-05" label-class="mb-n05 font-weight-bold">
                                <b-form-input
                                    id="room-input"
                                    v-model="selected.student.event.room"
                                    type="number"
                                    :disabled="!state.editing"
                                    placeholder="Not Selected"
                                    :clearable="true"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :class="['w-100', !state.editing ? 'form-control-plaintext' : 'form-control-plaintext']"/>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </b-card>
                    </b-col>
                    <b-col cols="12" class="col-xxl-6 col-xxxl">
                      <!-- Chaperone -->
                      <b-card no-body>
                        <b-card-body>
                          <b-row>
                            <b-col align-self="center" cols="auto">
                              <b-avatar :variant="`light-primary`" size="45">
                                <font-awesome-icon icon="fa-solid fa-people-roof" size="2x" />
                              </b-avatar>
                            </b-col>
                            <b-col align-self="center">
                              <b-form-group label="Chaperone" label-for="chaperone-input" class="m-0 p-0 mt-05" label-class="mb-n05 font-weight-bold">
                                <v-select id="chaperone-input"
                                          v-model="selected.student.event.chaperoneId"
                                          :disabled="!state.editing"
                                          label="name"
                                          :options="chaperones.items"
                                          :reduce="(option) => option.id"
                                          :loading="chaperones.loading"
                                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                          :select-on-tab="true"
                                          placeholder="Not Selected"
                                          :class="['w-100', !state.editing ? 'vs--plain-text' : 'vs--plain-text']">
                                  <template #option="{ name }">
                                    <span :class="{'text-ui-danger font-small-3': !name.first || !name.last}">
                                      {{ name.first || name.last ? getFullName({ name: name }) : 'Unnamed Chaperone' }}
                                    </span>
                                  </template>
                                  <template #selected-option="{ name }">
                                    <span :class="{'text-ui-danger': !name.first || !name.last}">
                                      {{ name.first || name.last ? getFullName({ name: name }) : 'Unnamed Chaperone' }}
                                    </span>
                                  </template>
                                </v-select>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </b-card>
                    </b-col>
                  </template>
                </b-row>

                <b-tabs v-if="selected.student" v-model="tab">
                  <b-tab :index="0" title="Details">
                    <b-row class="match-height">
                      <b-col class="col-12 col-xxxxl-6">
                        <b-card title="Demographics">
                          <b-row class="mb-1">
                            <b-col align-self="start" class="col-12">
                              <validation-provider v-slot="validationContext" vid="dob" name="Date of Birth" rules="required">
                                <b-form-group label="Date of Birth" label-for="grade-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                                  <date-of-birth v-model="selected.student.dob"
                                                 :test.sync="selected.student.dob"
                                                 :disabled="!state.editing"
                                                 :validation-state="getValidationState(validationContext)"
                                                 :student-grade="selected.student.grade"/>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                            <b-col align-self="start" class="col-12 col-md-6">
                              <validation-provider v-slot="validationContext" vid="zone" name="Sex" rules="required">
                                <b-form-group label="Sex" label-for="sex-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                                  <v-select id="sex-input"
                                            v-model="selected.student.sex"
                                            :disabled="!state.editing"
                                            label="text"
                                            :options="options.sexes"
                                            :reduce="option => option.value"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            class="w-100"/>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                            <b-col align-self="start" class="col-12 col-md-6">
                              <validation-provider v-slot="validationContext" vid="zone" name="Gender" rules="">
                                <b-form-group label="Gender" label-for="gender-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                                  <v-select id="sex-input"
                                            v-model="selected.student.gender"
                                            :disabled="!state.editing"
                                            label="text"
                                            :taggable="true"
                                            :options="options.genders"
                                            :create-option="option => ({ text: option, value: option })"
                                            :reduce="option => option.value"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            class="w-100"/>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                          </b-row>
                        </b-card>
                      </b-col>
                      <b-col class="col-12 col-xxxxl-6">
                        <b-card title="Contact">
                          <b-row>
                            <b-col class="col-12">
                              <validation-provider v-slot="validationContext" vid="phone-number-input" name="Phone Number" rules="required">
                                <b-form-group label="Phone Number" label-for="phone-number-input" :invalid-feedback="validationContext.errors[0]">
                                  <b-form-input id="phone-number-input"
                                                v-model="selected.student.phone.number"
                                                v-mask="'(###) ###-####'"
                                                placeholder="(555) 555-5555"
                                                :disabled="!state.editing"
                                                :state="getValidationState(validationContext)" />
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                            <b-col class="col-12">
                              <validation-provider v-slot="validationContext" vid="email-input" name="Email Address" rules="email">
                                <b-form-group label="Email Address" label-for="email-input" :invalid-feedback="validationContext.errors[0]">
                                  <b-form-input id="email-input" v-model="selected.student.email.address" :disabled="!state.editing" :state="getValidationState(validationContext)"></b-form-input>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                          </b-row>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-tab>
                  <b-tab :index="1" title="Notes" lazy>
                    <b-row v-if="selected.student.event">
                      <b-col v-if="!selected.student.event.notes">
                        <b-alert show variant="primary" class="mb-0 font-small-3">
                          <span class="font-weight-bolder">No Notes</span>
                          <transition name="fade">
                            <p v-if="state.editing">To the right of this tab, you can click the plus button to add a note.</p>
                            <p v-else>If you need to add a note, switch into edit mode.</p>
                          </transition>
                        </b-alert>
                      </b-col>
                      <b-col v-for="(note, index) in selected.student.event.notes" :key="note.id" align-self="start" class="col-12">
                        <b-card body-class="py-1" :class="[(index === 0 ? 'mt-0' : 'mt-1'), 'mb-0']">

                          <template v-if="state.editing">
                            <div class="d-flex justify-content-between align-items-center mb-50">
                              <label class="mb-0" :for="`note-${note.id}`">Note</label>
                              <b-button variant="link" size="sm" class="btn-icon pr-0" @click="removeStudentNote(note.id)">
                                <b-icon-x-circle/>
                              </b-button>
                            </div>
                            <b-form-group>
                              <b-textarea v-model="note.text"
                                          :plaintext="!state.editing"
                                          :disabled="!state.editing"
                                          :rows="2"
                                          :max-rows="2"
                                          no-resize
                                          :placeholder="note.placeholder"
                                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"/>
                            </b-form-group>
                            <b-form-group label="Type" :label-for="`note-${note.id}`">
                              <v-select v-model="note.type"
                                        :loading="options.notes.loading"
                                        :options="options.notes.items"
                                        :create-option="option => ({ label: option, value: option })"
                                        :reduce="option => option.value"
                                        :taggable="true"
                                        :select-on-tab="true"
                                        :input-id="`note-${note.id}`"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        @option:created="(option) => addStudentNoteOption(note, option)" />
                            </b-form-group>
                          </template>
                          <template v-else>
                            <b-media vertical-align="start">
                              <template #aside>
                                <b-avatar variant="light-primary" size="44px" badge-variant="secondary">
                                  <b-icon-file-text-fill style="width: 22px; height: 22px"/>
                                  <template #badge>
                                    <font-awesome-icon :icon="`fa-solid ${note.type === 'bus' ? 'fa-bus' : 'fa-question'}`"/>
                                  </template>
                                </b-avatar>
                              </template>
                              <b-card-text style="white-space: pre-line" class="mb-0">{{ note.text }}</b-card-text>
                              <b-badge variant="primary" class="mt-25">{{ note.type }}</b-badge>
                            </b-media>
                          </template>
                        </b-card>
                      </b-col>
                    </b-row>

                  </b-tab>
                  <b-tab :index="2" title="Roommates" lazy>
                    <b-row v-if="selectedStudentRoommates.occupants.length">
                      <b-col v-for="(occupant) in selectedStudentRoommates.occupants" :key="occupant.id" cols="12">
                        <!-- Roommate -->
                        <b-card no-body>
                          <b-card-body>
                            <b-row>
                              <b-col align-self="start" cols="auto">
                                <b-avatar :variant="`light-primary`" size="44px" :badge-variant="getSexBadgeVariant(occupant.raw)">
                                  <font-awesome-icon :icon="getSexIcon(occupant.raw)" style="width: 22px; height: 22px" />
                                  <template #badge>
                                    {{ getSexBadge(occupant.raw) }}
                                  </template>
                                </b-avatar>
                              </b-col>
                              <b-col align-self="center">
                                <div class="truncate">
                                  <h5 class="mb-25 font-weight-bolder">
                                    {{ occupant.name.first }} {{ occupant.name.last }}
                                  </h5>
                                  <div v-if="occupant.raw.school" class="font-small-3">
                                    {{ occupant.raw.school.name.legal }}
                                  </div>
                                  <div v-if="occupant.raw.selection" class="font-small-3">
                                    {{ occupant.raw.selection.ensemble.name }} - {{ occupant.raw.selection.instrument.name }}
                                  </div>
                                </div>
                              </b-col>
                            </b-row>
                          </b-card-body>
                        </b-card>
                      </b-col>
                    </b-row>
                    <b-row v-else>
                      <b-col>
                        <b-card>
                          No Roommates
                          <div class="text-muted font-small-3">Roommates are determined by room number. </div>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-tab>
                  <b-tab :index="3" title="Checkins" lazy>
                    <b-row>
                      <b-col v-for="day in options.days" :key="day.value" class="col-12 col-xxl-4">
                        <b-card no-body>
                          <b-card-body>
                            <b-row>
                              <b-col align-self="center" cols="auto" class="pr-50">
                                <b-avatar :variant="`light-primary`" size="45">
                                  <font-awesome-icon :icon="['far', getCheckinIcon(selected.student.event.checkin[day.value])]" size="2x"/>
                                </b-avatar>
                              </b-col>
                              <b-col align-self="center">
                                <b-form-group :label="day.label" label-for="room-input" class="m-0 p-0 mt-05" label-class="mb-n05 font-weight-bold">
                                  <v-select id="chaperone-input"
                                            v-model="selected.student.event.checkin[day.value]"
                                            :disabled="!state.editing"
                                            :reduce="(option) => option.value"
                                            label="label"
                                            :options="options.checkin"
                                            :loading="chaperones.loading"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            :select-on-tab="true"
                                            placeholder="Not Selected"
                                            :class="['w-100', !state.editing ? 'vs--plain-text' : 'vs--plain-text']"/>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-card-body>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-tab>
                  <b-tab :index="4" title="Badges" lazy>
                    <student-badges :selected="selected" :buses="buses" :state="state"/>
                  </b-tab>
                  <template #tabs-end>
                    <template v-if="tab === 1 && state.editing">
                      <b-button variant="link" size="sm" class="btn-icon ml-auto pr-0" @click="addStudentNote">
                        <b-icon-plus-circle style="width: 14px; height: 14px"/>
                      </b-button>
                    </template>
                  </template>
                </b-tabs>
              </form>
            </validation-observer>
          </template>
          <template v-else>
            <b-card>
              Student Not Selected
            </b-card>
          </template>
        </template>
      </event-layout>
    </template>

    <template #debug>
      <b-row>
        <b-col>
          <debug title="Students" collapsed>{{ students }}</debug>
        </b-col>
        <b-col>
          <debug title="Selected Student" collapsed>{{ selected.student }}</debug>
        </b-col>
        <b-col>
          <debug title="Initial Student" collapsed>{{ selected.initialStudent }}</debug>
        </b-col>
      </b-row>
    </template>
  </page-layout>
</template>

<script>
import draggable from 'vuedraggable';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { uuid } from 'vue-uuid';
import PageLayout from '@/components/PageLayout.vue';
import RoomModal from '@/views/events/hotels/RoomModal.vue';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import Fuse from 'fuse.js';
import {API, graphqlOperation} from 'aws-amplify';
import { listBuses } from '@/graphql/queries/events-students';
import ZipInput from '@/components/ZipInput.vue';
import StateInput from '@/components/StateInput.vue';
import vSelect from 'vue-select';
import DateOfBirth from '@/components/DateOfBirth.vue';
import LastModified from '@/components/LastModified.vue';
import {VueMaskDirective} from 'v-mask';
import OverlayLoading from '@/components/OverlayLoading.vue';
import settingsMixin from '@/mixins/settings.mixin';
import SortInput from '@/components/SortInput.vue';
import {
  getHotels,
  getChaperones,
  getStudentsFromSelections,
  createStudentEvent,
  updateStudent,
  getSettings,
  Occupant, Room, deleteStudentEvents
} from '@/views/events/service';
import notify from '@/mixins/notify.mixin';
import events from '@/mixins/event.mixin';
import styleMixin from '@/mixins/style.mixin';
import EventsNav from '@/views/events/EventsNav.vue';
import EventLayout from '@/views/events/EventLayout.vue';
import { checkins, days, genders, grades, sexes } from '@/views/events/service/data';
import StudentBadges from '@/views/events/students/StudentBadges.vue';

export default {
  name: 'Students',
  directives: { mask: VueMaskDirective },
  components: {
    StudentBadges,
    EventLayout,
    EventsNav,
    SortInput,
    OverlayLoading,
    LastModified,
    StateInput,
    ZipInput,
    DateOfBirth,
    PageLayout,
    RoomModal,
    BCardActions,
    draggable,
    VuePerfectScrollbar,
    vSelect
  },
  mixins: [ settingsMixin, events, notify, styleMixin ],
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      selected: {
        student: null,
        initialStudent: null,
        loading: false,
      },
      students: {
        loading: true,
        search: null,
        items: [],
        filters: {
          busId: null,
          hotelId: null,
          chaperoneId: null,
          sex: null,
          gender: null,
          medicalReceived: null
        },
        sorting: {
          by: ['instrument.name', 'name.last', 'name.first'],
          desc: false,
          drag: false,
          options: [
            { label: 'First Name', value: 'name.first'},
            { label: 'Last Name', value: 'name.last'},
            { label: 'Instrument', value: 'instrument.name'},
          ]
        },
      },
      buses: {
        loading: true,
        items: [ ],
      },
      hotels: {
        loading: true,
        items: [ ],
      },
      chaperones: {
        loading: true,
        items: [ ],
      },
      roommates: {
        loading: true,
        items: [ ],
      },
      options: {
        grades: grades,
        checkin: checkins,
        days: days,
        sexes: sexes,
        genders: genders,
        schools: {
          items: [],
          loading: false
        },
        notes: {
          items: [
            { value: 'bus', label: 'Bus' }
          ],
          loading: true
        }
      },
      icon: 'fas fa-graduation-cap',
      tab: 0,
      // eslint-disable-next-line global-require
      image: require('@/assets/images/illustration/storyset-id-card-rafiki.svg'),
      sidebar: true
    }
  },
  computed: {
    hasChanged() {
      return JSON.stringify(this.selected.student) !== JSON.stringify(this.selected.initialStudent);
    },
    computedStudents() {
      let { items } = this.students
      const { busId, chaperoneId, hotelId, sex, gender, medicalReceived } = this.students.filters

      if (gender !== null) {
        items = items.filter((item) => (gender ? !item.gender : item.gender));
      }

      if (medicalReceived !== null) {
        items = items.filter((item) => item.event?.medicalReceived === medicalReceived);
      }

      const fuse = new Fuse(items, {
        useExtendedSearch: true,
        keys: [
          'id',
          'name.first',
          'name.last',
          'sex',
          'event.busId',
          'event.hotelId',
          'event.chaperoneId'
        ]
      })

      const query = { $and: [ ] }
      if(busId) { query.$and.push({ 'event.busId': busId }) }
      if(hotelId) { query.$and.push({ 'event.hotelId': hotelId }) }
      if(chaperoneId) { query.$and.push({ 'event.chaperoneId': chaperoneId }) }
      if(sex) { query.$and.push({ sex: sex }) }
      if(this.students.search) {
        query.$and.push({
          $or: [
            { 'name.first': `'${this.students.search}` },
            { 'name.last': `'${this.students.search}`}
          ]
        })
      }

      
      if(query.$and.length) { items = fuse.search(query).map(({ item }) => item) }
      return items
    },
    selectedStudentRoommates() {
      const roomNumber = this.selected?.student?.event?.room ?? 0
      const occupants = this.students.items
        .filter(student => student.event?.room === roomNumber && student.id !== this.selected.student.id)
        .map(student => new Occupant('student', student))
      return new Room(roomNumber, occupants)
    },
  },
  async created() {
    await this.listStudents()
    await this.listBuses()
    await this.listHotels()
    await this.listChaperones()
    await this.$nextTick(() => {
      if(this.id) this.selectStudent(this.students.items.find(student => student.id === this.id))
      setTimeout( () => { this.$refs.layout.state.loading = false }, 500);
    })
  },
  methods: {
    /* eslint-disable no-await-in-loop */
    async refresh() {
      this.$refs.layout.state.loading = true
      await this.listStudents()
      this.$refs.layout.state.loading = false
    },
    async listStudents() {
      this.students.loading = true;
      await getStudentsFromSelections().then(students => {
        this.students.items = students;
        this.students.loading = false;
        this.students.loaded = true;

         const notes = new Set(this.students.items.filter(student => student?.event?.notes).reduce((acc, student) => {
           student.event.notes.filter(note => note.type).forEach(note => {
             acc.push(note.type)
           })
            return acc
         }, []).concat(this.options.notes.items.map(note => note.value)))
         this.options.notes.items = Array.from(notes).map(note => ({ value: note, label: note }))
        this.options.notes.loading = false
      })
    },
    async selectStudent(student) {
      if(student.eventId === null) {
        this.selected.loading = true;
        await createStudentEvent({ studentId: student.id }).then(event => {
          student.eventId = event.id
          student.event = event
        })
        .catch(err => {
          console.error(err)
        })
      }
      if(this.$route.params.id !== student.id) {
        await this.$router.push({ name: 'events-student', params: { id: student.id } })
      }
      this.selected.loading = false;
      this.selected.student = student;
      this.selected.initialStudent = JSON.parse(JSON.stringify(student));
    },
    async deselectStudent() {
      this.selected.student = null;
      this.selected.initialStudent = null;
      if(this.$route.params.id !== null) {
        await this.$router.push({ name: 'events-students', params: { id: null } })
      }
    },
    async updateStudent() {
      await updateStudent(this.selected.student).then(() => {
        this.updateSelectedStudentInitial();
        this.$refs.observer.reset();
        this.notify({ title: 'Success', text: 'Student was successfully updated', icon: this.icon, variant: 'success' });
      })
    },
    async deleteStudents(students) {
      this.$refs.layout.state.saving = true
      this.selected.student = null
      this.selected.initialStudent = null
      const studentEvents = students.map(student => (student?.event ? { ...student?.event } : null) ).filter(value => value !== null)
      await deleteStudentEvents(studentEvents).then(() => {
        students.forEach(student => {
          student.eventId = null
          student.event = null
        })
        this.notify({ title: 'Success', text: 'Student Events were successfully deleted', icon: this.icon, variant: 'success' });
      })
          .catch(err => {
            console.error(err)
            this.notify({ title: 'Error', text: 'An error occurred while deleting student events', icon: this.icon, variant: 'danger' });
          })

      this.$refs.layout.state.saving = false
    },
    addStudentNoteOption(selectedNote, option) {
      if(!this.options.notes.items.find(note => note.label === option.label)) {
        this.options.notes.items.push({ value: option.label, label: option.label })
      }
      selectedNote.type = option.label
      const test = this.selected.student.event.notes.find(note => note.id === selectedNote.id)
      if(test) {
        test.type = option.label
      }
    },

    addStudentNote() {
      if(!this.selected.student.event.notes) {
        this.selected.student.event.notes = []
      }
      this.selected.student.event.notes.push({
        id: uuid.v4(),
        type: 'bus',
        text: ''
      })
    },
    removeStudentNote(id) {
      this.selected.student.event.notes = this.selected.student.event.notes.filter(note => note.id !== id)
      if(this.selected.student.event.notes.length === 0) {
        this.selected.student.event.notes = null
      }
    },
    resetSelectedStudent() {
      // Deep clone the initialBus object to selected chaperone.
      this.selected.student = JSON.parse(JSON.stringify(this.selected.initialStudent))

      // Find the index of the bus with the matching id in the buses array
      const index = this.students.items.findIndex(student => student.id === this.selected.student.id);

      // Replace the bus at the found index with the selected bus
      if (index !== -1) {
        this.students.items.splice(index, 1, this.selected.student);
      }
    },
    updateSelectedStudentInitial() {
      this.selected.initialStudent = JSON.parse(JSON.stringify(this.selected.student))
    },

    async listBuses() {
      this.buses.loading = true;
      const buses = [];
      const current = this.$store.getters['settings/getCurrent'];
      let nextToken = null;

      do {
        const response = await API.graphql(graphqlOperation(listBuses, {
          filter: {
            createdAt: { between: [current.year.start, current.year.end] }
          },
          limit: 500,
          nextToken: nextToken
        }));
        buses.push(...response.data.listBuses.items);
        nextToken = response.data.listBuses.nextToken;
      }
      while (nextToken);

      this.buses.items = buses;
      this.buses.loading = false;
      this.buses.loaded = true;
    },
    async listHotels() {
      this.hotels.loading = true;
      await getHotels().then(hotels => {
        this.hotels.items = hotels;
        this.hotels.loading = false;
        this.hotels.loaded = true;
      })
    },
    async listChaperones() {
      this.chaperones.loading = true;
      await getChaperones().then(chaperones => {
        this.chaperones.items = chaperones;
        this.chaperones.loading = false;
        this.chaperones.loaded = true;
      })
    },

    getValidationState({ dirty, validated, valid = null }) {
      if(this.$refs.layout.state.editing) {
        return dirty || validated ? valid : null;
      }
      return null;
    },
    getCheckinIcon(value) {
      if(value === null) { return 'fa-calendar' }
      return value === true ? 'fa-calendar-check' : 'fa-calendar-xmark'
    },
  },
}
</script>

<style scoped lang="scss">
.list-group:empty,
.list-group > div:empty {
  padding:1rem;
  margin-right: .5rem;
  text-align:left;
  border: 1px dashed rgba(34, 41, 47, 0.125);
}

.students.list-group:empty:before,
.students.list-group > div:empty:before {
  content: 'No Students Selected...';
}
</style>

<script>

import printableSettingsMixin from '@/views/printable/settings/settings.mixin';
import styleMixin from '@/mixins/style.mixin';
import settingsMixin from '@/mixins/settings.mixin';
import {
  getSettings,
} from '@/views/events/service';
import BadgePrintModal from '@/views/events/students/BadgePrintModal.vue';
import Badge from '@/views/events/badges/Badge.vue';

export default {
  name: 'StudentBadges',
  components: {
    BadgePrintModal,
    Badge
  },
  mixins: [ printableSettingsMixin, styleMixin, settingsMixin],
  props: {
    selected: {
      type: Object,
      required: true
    },
    buses: {
      type: Object,
      required: true
    },
    state: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      image: require('@/assets/images/illustration/storyset-id-card-rafiki.svg'),
      badges: {
        albany: {
          loading: true,
          loaded: false,
          settings: {
            container: {
              style: {
                paddingTop: {
                  value: null,
                  unit: null
                },
                paddingBottom: {
                  value: null,
                  unit: null
                },
                paddingLeft: {
                  value: null,
                  unit: null
                },
                paddingRight: {
                  value: null,
                  unit: null
                },
                gridColumnGap: {
                  value: null,
                  unit: null
                },
                gridRowGap: {
                  value: null,
                  unit: null
                }
              }
            },
            badge: {
              style: {
                color: null,
                fontFamily: null,
                fontWeight: null,
                borderColor: null,
                borderStyle: null,
                borderWidth: {
                  value: null,
                  unit: null
                },
                width: null,
                height: null,
                margin: {
                  value: null,
                  unit: null
                },
                paddingTop: {
                  value: null,
                  unit: null
                },
                paddingBottom: {
                  value: null,
                  unit: null
                },
                paddingLeft: {
                  value: null,
                  unit: null
                },
                paddingRight: {
                  value: null,
                  unit: null
                },
              },
            },
            body: {
              style: {
                borderColor: null,
                borderStyle: null,
                borderWidth: {
                  value: null,
                  unit: null
                },
                paddingTop: {
                  value: null,
                  unit: null
                },
                paddingBottom: {
                  value: null,
                  unit: null
                },
                paddingLeft: {
                  value: null,
                  unit: null
                },
                paddingRight: {
                  value: null,
                  unit: null
                },
              }
            },
            logo: {
              src: null,
              style: {
                width: {
                  value: null,
                  unit: null
                },
                height: {
                  value: null,
                  unit: null
                },
                borderWidth: {
                  value: null,
                  unit: null
                },
                borderStyle: null,
                borderColor: null,
                borderRadius: null,
                objectFit: null,
                marginTop: {
                  value: null,
                  unit: null
                },
                marginBottom: {
                  value: null,
                  unit: null
                },
              }
            },
            student: {
              short: {
                enabled: true,
                format: null,
                style: {
                  fontSize: {
                    value: null,
                    unit: null
                  },
                  fontWeight: null,
                  marginTop: {
                    value: null,
                    unit: null
                  },
                  marginBottom: {
                    value: null,
                    unit: null
                  },
                  marginLeft: {
                    value: null,
                    unit: null
                  },
                  marginRight: {
                    value: null,
                    unit: null
                  },
                  paddingTop: {
                    value: null,
                    unit: null
                  },
                  paddingBottom: {
                    value: null,
                    unit: null
                  },
                  paddingLeft: {
                    value: null,
                    unit: null
                  },
                  paddingRight: {
                    value: null,
                    unit: null
                  },
                }
              },
              full: {
                enabled: true,
                format: null,
                style: {
                  fontSize: {
                    value: null,
                    unit: null
                  },
                  fontWeight: null,
                  marginTop: {
                    value: null,
                    unit: null
                  },
                  marginBottom: {
                    value: null,
                    unit: null
                  },
                  marginLeft: {
                    value: null,
                    unit: null
                  },
                  marginRight: {
                    value: null,
                    unit: null
                  },
                  paddingTop: {
                    value: null,
                    unit: null
                  },
                  paddingBottom: {
                    value: null,
                    unit: null
                  },
                  paddingLeft: {
                    value: null,
                    unit: null
                  },
                  paddingRight: {
                    value: null,
                    unit: null
                  },
                }
              },
            },
            ensemble: {
              enabled: true,
              text: '',
              style: {
                fontSize: {
                  value: null,
                  unit: null
                },
                fontWeight: null,
                marginTop: {
                  value: null,
                  unit: null
                },
                marginBottom: {
                  value: null,
                  unit: null
                },
                marginLeft: {
                  value: null,
                  unit: null
                },
                marginRight: {
                  value: null,
                  unit: null
                },
                paddingTop: {
                  value: null,
                  unit: null
                },
                paddingBottom: {
                  value: null,
                  unit: null
                },
                paddingLeft: {
                  value: null,
                  unit: null
                },
                paddingRight: {
                  value: null,
                  unit: null
                },
              }
            },
            footer: {
              text: `All-State ${new Date().getFullYear()}`,
              style: {
                background: null,
                color: null,
                display: null,
                fontSize: {
                  value: null,
                  unit: null
                },
                fontWeight: null,
                justifyContent: null,
                marginBottom: {
                  value: null,
                  unit: null
                },
                marginLeft: {
                  value: null,
                  unit: null
                },
                marginRight: {
                  value: null,
                  unit: null
                },
                paddingTop: {
                  value: null,
                  unit: null
                },
                paddingBottom: {
                  value: null,
                  unit: null
                },
                paddingLeft: {
                  value: null,
                  unit: null
                },
                paddingRight: {
                  value: null,
                  unit: null
                },
              },
            },
          },
        },
        conference: {
          loading: true,
          loaded: false,
          settings: {
            container: {
              style: {
                paddingTop: {
                  value: null,
                  unit: null
                },
                paddingBottom: {
                  value: null,
                  unit: null
                },
                paddingLeft: {
                  value: null,
                  unit: null
                },
                paddingRight: {
                  value: null,
                  unit: null
                },
                gridColumnGap: {
                  value: null,
                  unit: null
                },
                gridRowGap: {
                  value: null,
                  unit: null
                }
              }
            },
            badge: {
              style: {
                color: null,
                fontFamily: null,
                fontWeight: null,
                borderColor: null,
                borderStyle: null,
                borderWidth: {
                  value: null,
                  unit: null
                },
                width: null,
                height: null,
                margin: {
                  value: null,
                  unit: null
                },
                paddingTop: {
                  value: null,
                  unit: null
                },
                paddingBottom: {
                  value: null,
                  unit: null
                },
                paddingLeft: {
                  value: null,
                  unit: null
                },
                paddingRight: {
                  value: null,
                  unit: null
                },
              },
            },
            body: {
              style: {
                borderColor: null,
                borderStyle: null,
                borderWidth: {
                  value: null,
                  unit: null
                },
                paddingTop: {
                  value: null,
                  unit: null
                },
                paddingBottom: {
                  value: null,
                  unit: null
                },
                paddingLeft: {
                  value: null,
                  unit: null
                },
                paddingRight: {
                  value: null,
                  unit: null
                },
              }
            },
            logo: {
              src: null,
              style: {
                width: {
                  value: null,
                  unit: null
                },
                height: {
                  value: null,
                  unit: null
                },
                borderWidth: {
                  value: null,
                  unit: null
                },
                borderStyle: null,
                borderColor: null,
                borderRadius: null,
                objectFit: null,
                marginTop: {
                  value: null,
                  unit: null
                },
                marginBottom: {
                  value: null,
                  unit: null
                },
              }
            },
            student: {
              short: {
                enabled: true,
                format: null,
                style: {
                  fontSize: {
                    value: null,
                    unit: null
                  },
                  fontWeight: null,
                  marginTop: {
                    value: null,
                    unit: null
                  },
                  marginBottom: {
                    value: null,
                    unit: null
                  },
                  marginLeft: {
                    value: null,
                    unit: null
                  },
                  marginRight: {
                    value: null,
                    unit: null
                  },
                  paddingTop: {
                    value: null,
                    unit: null
                  },
                  paddingBottom: {
                    value: null,
                    unit: null
                  },
                  paddingLeft: {
                    value: null,
                    unit: null
                  },
                  paddingRight: {
                    value: null,
                    unit: null
                  },
                }
              },
              full: {
                enabled: true,
                format: null,
                style: {
                  fontSize: {
                    value: null,
                    unit: null
                  },
                  fontWeight: null,
                  marginTop: {
                    value: null,
                    unit: null
                  },
                  marginBottom: {
                    value: null,
                    unit: null
                  },
                  marginLeft: {
                    value: null,
                    unit: null
                  },
                  marginRight: {
                    value: null,
                    unit: null
                  },
                  paddingTop: {
                    value: null,
                    unit: null
                  },
                  paddingBottom: {
                    value: null,
                    unit: null
                  },
                  paddingLeft: {
                    value: null,
                    unit: null
                  },
                  paddingRight: {
                    value: null,
                    unit: null
                  },
                }
              },
            },
            ensemble: {
              enabled: true,
              text: '',
              style: {
                fontSize: {
                  value: null,
                  unit: null
                },
                fontWeight: null,
                marginTop: {
                  value: null,
                  unit: null
                },
                marginBottom: {
                  value: null,
                  unit: null
                },
                marginLeft: {
                  value: null,
                  unit: null
                },
                marginRight: {
                  value: null,
                  unit: null
                },
                paddingTop: {
                  value: null,
                  unit: null
                },
                paddingBottom: {
                  value: null,
                  unit: null
                },
                paddingLeft: {
                  value: null,
                  unit: null
                },
                paddingRight: {
                  value: null,
                  unit: null
                },
              }
            },
            bus: {
              enabled: true,
              text: '',
              textFormat: null,
              style: {
                fontSize: {
                  value: null,
                  unit: null
                },
                fontWeight: null,
                marginTop: {
                  value: null,
                  unit: null
                },
                marginBottom: {
                  value: null,
                  unit: null
                },
                marginLeft: {
                  value: null,
                  unit: null
                },
                marginRight: {
                  value: null,
                  unit: null
                },
                paddingTop: {
                  value: null,
                  unit: null
                },
                paddingBottom: {
                  value: null,
                  unit: null
                },
                paddingLeft: {
                  value: null,
                  unit: null
                },
                paddingRight: {
                  value: null,
                  unit: null
                },
              }
            },
            footer: {
              text: `All-State ${new Date().getFullYear()}`,
              style: {
                background: null,
                color: null,
                display: null,
                fontSize: {
                  value: null,
                  unit: null
                },
                fontWeight: null,
                justifyContent: null,
                marginBottom: {
                  value: null,
                  unit: null
                },
                marginLeft: {
                  value: null,
                  unit: null
                },
                marginRight: {
                  value: null,
                  unit: null
                },
                paddingTop: {
                  value: null,
                  unit: null
                },
                paddingBottom: {
                  value: null,
                  unit: null
                },
                paddingLeft: {
                  value: null,
                  unit: null
                },
                paddingRight: {
                  value: null,
                  unit: null
                },
              },
            },
          },
        },
        blank: {
          name: {
            short: null,
            full: null
          },
          ensemble: null,
          bus: null
        }
      },
      includeAlbany: false,
      includeConference: false,
      includeBlank: false,
    }
  },
  computed: {
    albanyBadgeSettings() {
      if(this.badges.albany.loading && !this.badges.albany.loaded) {
        this.setBadgeAlbany()
      }
      return this.badges.albany
    },
    conferenceBadgeSettings() {
      if(this.badges.conference.loading) {
        this.setBadgeConference()
      }
      return this.badges.conference
    },
    isLoading() {
      return this.badges.albany.loading || this.badges.conference.loading
    }
  },
  methods: {
    async setBadgeAlbany() {
      const settings = await getSettings('badges-albany');
      if(settings) {
        this.badges.albany.settings = this.deepMerge(this.badges.albany.settings, settings);
        if(this.badges.albany.settings.logo.src === null) {
          // eslint-disable-next-line global-require
          this.badges.albany.settings.logo.src = require('@/assets/images/logo/logo5-blue-white-gold.svg')
        }
      }
      this.badges.albany.loading = false;
      this.badges.albany.loaded = true;
    },
    async setBadgeConference() {
       const settings = await getSettings('badges-conference');
      if(settings) {
        this.badges.conference.settings = this.deepMerge(this.badges.conference.settings, settings);
        if(this.badges.conference.settings.logo.src === null) {
          // eslint-disable-next-line global-require
          this.badges.conference.settings.logo.src = require('@/assets/images/logo/logo5-blue-white-gold.svg')
        }
      }
      this.badges.conference.loading = false;
    },
    getBus(busId) {
      return this.buses?.items?.find(bus => bus.id === busId) ?? null;
    },
    openModal(modalId) {
      this.$bvModal.show(modalId)
    },
    clear() {
      this.includeAlbany = false;
      this.includeConference = false;
      this.includeBlank = false;
      this.badges.blank.name.short = null;
    }
  }
}
</script>

<template>
  <b-overlay :show="isLoading">
    <div class="name-badge-container shadow-none mt-2" :style="computedStyle(badges.albany.container ? badges.albany.container.style : { width: '100%'})">
      <div class="p-0">
        <b-card-title class="text-center h5">Albany Badge</b-card-title>
        <badge
            :settings="albanyBadgeSettings.settings"
            :include-student-short-name="albanyBadgeSettings.settings.student.short.enabled"
            :include-student-full-name="albanyBadgeSettings.settings.student.full.enabled"
            :include-ensemble="albanyBadgeSettings.settings.ensemble.enabled"

            :student-name-short="selected.student.name"
            :student-name-full="selected.student.name"
            :ensemble="selected.student.selection.ensemble.name"
            
        />
        <b-button block variant="outline-primary"
                  class="mt-1 bg-light-primary"
                  @click="() => {
                    includeAlbany = true;
                    openModal('student-badge');
                  }">
          Print
        </b-button>
      </div>
      <div class="p-0">
        <b-card-title class="text-center h5">Conference Badge</b-card-title>
        <badge
            :settings="conferenceBadgeSettings.settings"
            :include-student-short-name="conferenceBadgeSettings.settings.student.short.enabled"
            :include-student-full-name="conferenceBadgeSettings.settings.student.full.enabled"
            :include-ensemble="conferenceBadgeSettings.settings.ensemble.enabled"
            :include-bus="conferenceBadgeSettings.settings.bus.enabled"

            :student-name-short="selected.student.name"
            :student-name-full="selected.student.name"
            :ensemble="selected.student.selection.ensemble.name"
            :bus="getBus(selected.student.event.busId)"
            
        />
        <b-button block variant="outline-primary"
                  class="mt-1 bg-light-primary"
                  @click="() => {
                    includeConference = true;
                    openModal('student-badge');
                  }">
          Print
        </b-button>
      </div>
      <div>
        <b-card-title class="text-center h5">Blank Badge</b-card-title>
        <badge
            :settings="conferenceBadgeSettings.settings"
            :include-student-short-name="conferenceBadgeSettings.settings.student.short.enabled"
            :include-student-full-name="conferenceBadgeSettings.settings.student.full.enabled"
            :include-ensemble="conferenceBadgeSettings.settings.ensemble.enabled"
            :include-bus="conferenceBadgeSettings.settings.bus.enabled"
            :is-blanks="true"
            :is-editable="state.editing"
            class="name-badge-editor"
            @input:name-short="(value) => badges.blank.name.short = value"
            @input:name-full="(value) => badges.blank.name.full = value"
            @input:ensemble="(value) => badges.blank.ensemble = value"
            @input:bus="(value) => badges.blank.bus = value"
        />
        <b-button block variant="outline-primary"
                  class="mt-1 bg-light-primary"
                  @click="() => {
                    includeBlank = true;
                    openModal('student-badge');
                  }">
          Print
        </b-button>
      </div>
      <!-- Print Card -->
<!--      <b-card class="bus-roster-card mb-2" body-class="pt-0" :img-src="image" img-alt="Bus" img-top tag="article">
        <b-card-title class="mb-0">Name Badges</b-card-title>
        <b-card-text class="mt-50">
          <p class="mb-50">To print all name badges, click the button below.</p>
          <span class="font-small-3">For individual name badges, please click the print button below the badge.</span>
        </b-card-text>
        <b-button block variant="primary" @click="() => {
                    includeAlbany = true;
                    includeConference = true;
                    includeBlank = true;
                    openModal('student-badge');
                  }">Print Name Badges</b-button>
      </b-card>-->

      <badge-print-modal id="student-badge" :loading="isLoading" @after-print="clear()">
        <template v-if="includeAlbany" #albany>
          <badge
              :settings="albanyBadgeSettings.settings"
              :include-student-short-name="albanyBadgeSettings.settings.student.short.enabled"
              :include-student-full-name="albanyBadgeSettings.settings.student.full.enabled"
              :include-ensemble="albanyBadgeSettings.settings.ensemble.enabled"

              :student-name-short="selected.student.name"
              :student-name-full="selected.student.name"
              :student-name="selected.student.name"
              :ensemble="selected.student.selection.ensemble.name"
          />
        </template>
        <template v-if="includeConference" #conference>
          <badge
              :settings="conferenceBadgeSettings.settings"
              :include-student-short-name="conferenceBadgeSettings.settings.student.short.enabled"
              :include-student-full-name="conferenceBadgeSettings.settings.student.full.enabled"
              :include-ensemble="conferenceBadgeSettings.settings.ensemble.enabled"
              :include-bus="conferenceBadgeSettings.settings.bus.enabled"

              :student-name-short="selected.student.name"
              :student-name-full="selected.student.name"
              :ensemble="selected.student.selection.ensemble.name"
              :bus="getBus(selected.student.event.busId)"
          />
        </template>
        <template v-if="includeBlank" #blank>
          <badge
              :settings="conferenceBadgeSettings.settings"
              :include-student-short-name="conferenceBadgeSettings.settings.student.short.enabled"
              :include-student-full-name="conferenceBadgeSettings.settings.student.full.enabled"
              :include-ensemble="conferenceBadgeSettings.settings.ensemble.enabled"
              :include-bus="conferenceBadgeSettings.settings.bus.enabled"
              :student-name-short="badges.blank.name.short"
              :student-name-full="badges.blank.name.full"
              :ensemble="badges.blank.ensemble"
              :bus="{ name: badges.blank.bus }"
              class="name-badge-editor"
          />
        </template>
      </badge-print-modal>
    </div>
  </b-overlay>
</template>

<style scoped>
/* Scaling for smaller screens */

.name-badge-container {
  background-color: transparent;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(4in, 1fr) );
  grid-template-rows: repeat(auto-fit, minmax(3in, 1fr));
  grid-column-gap: 0.1667in;
  grid-row-gap: 0.1667in;
  //padding: 0.1667in;
  box-sizing: border-box;
  border-radius: 0.428rem;
  //box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  justify-items: center;
  align-content: space-between;
  align-items: center;
}

.name-badge {
  border: 1px dashed #ccc;
  border-radius: 0.428rem;
  width: 4in;
  height: 3in;
  font-size: 100%;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}

.name-badge-container:not(:first-child) {
  margin-top: 2rem
}

.name-badge-container-paging {
  width: 8.5in!important;
  margin-top: 1rem;
}

.sorting-btn-border {
  border: 1px solid #d8d6de;
}
</style>

<template>
  <b-modal :id="id" centered size="sm" :title="selectedRoom ? 'Edit Room' : 'New Room'" no-close-on-backdrop footer-class="d-block" @hidden="clear">
    <!-- Content -->
    <template #default>
      <b-container>
        <validation-observer ref="observer" v-slot="{ handleSubmit }" tag="div">
          <form ref="form" @submit.stop.prevent="handleSubmit(selectedRoom ? create : update)">
            <b-row>
<!--              <b-col cols="12">
                <validation-provider v-slot="validationContext" vid="floor" name="Floor" :rules="rules.floor">
                  <b-form-group label="Floor" label-for="floor" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="floor" v-model="room.floor" type="number" number :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>-->
              <b-col cols="12">
                <validation-provider v-slot="validationContext" vid="number" name="Room Number" :rules="rules.number">
                  <b-form-group label="Room Number" label-for="number" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="number" v-model="room.number" type="number" number :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <validation-provider v-slot="validationContext" vid="beds" name="Beds" :rules="rules.beds">
                  <b-form-group label="Beds" label-for="beds" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="beds" v-model="room.beds" type="number" number :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </form>
        </validation-observer>
      </b-container>
    </template>

    <!-- Footer -->
    <template #modal-footer>
      <b-row>
        <b-col v-if="selectedRoom" align-self="center" class="text-right">
          <b-button type="submit" variant="primary" @click="$refs.observer.handleSubmit(update)">Update</b-button>
        </b-col>
        <b-col v-else align-self="center" class="text-right">
          <b-button type="reset" class="mr-1" @click="clear()">Clear</b-button>
          <b-button type="submit" variant="primary" @click="$refs.observer.handleSubmit(create)">Add</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
  import vSelect from 'vue-select'
  import { uuid } from 'vue-uuid';
  import notify from '@/mixins/notify.mixin'
  import slugify from 'slugify';
  import {API, graphqlOperation} from 'aws-amplify';
  import {listForms, listZones} from '@/graphql/queries';
  import {createDistrict, createInstrument} from '@/graphql/mutations';
  import Avatar from '@/models/avatar';
  import StateInput from '@/components/StateInput.vue';
  import { rules } from '@/data/validation-rules';
  import ZipInput from '@/components/ZipInput.vue';

  export default {
    name: 'RoomModal',
    components: {
      ZipInput,
      StateInput,
      vSelect
    },
    mixins: [ notify ],
    props: {
      id: {
        type: String,
        required: true,
      },
      selectedRoom: {
        type: Object,
        required: false,
        default: null
      },
      selectedHotelRooms: {
        type: Array,
        default: () => []
      },
    },
    data() {
      return {
        room: {
          id: this.selectedRoom?.id,
          floor: this.selectedRoom?.floor,
          number: this.selectedRoom?.number,
          beds: this.selectedRoom?.beds
        },
        rules: {
          floor: {
            required: true,
            numeric: true,
            min: 1,
            max: 2
          },
          number: {
            required: true,
            numeric: true,
            min: 3,
            max: 3,
            excluded: this.selectedHotelRooms
          },
          beds: {
            required: true,
            numeric: true,
            max: 1,
            max_value: 3
          }
        },
        state: {
          redirect: false
        },
      }
    },
    computed: {

    },
    watch: {
      selectedRoom(room) {
        this.room = {
          id: room?.id,
          //floor: room?.floor,
          number: room?.number,
          beds: room?.beds,
        }
      },
      selectedHotelRooms(rooms) {
        this.rules.number.excluded = rooms
      }
    },
    methods: {
      async create() {
        try {
          const input = {
            id: uuid.v4(),
            //floor: this.room.floor,
            number: this.room.number,
            beds: this.room.beds,
          }

          //await API.graphql(graphqlOperation(createDistrict, { input: input }));
          await this.notify({ title: 'Success', text: 'Room was successfully created', icon: 'fas fa-server', variant: 'success' });

          this.$emit('add', input)
          this.$root.$emit('bv::toggle::modal', this.id)
        }
        catch (error) {
          console.error(error)
          this.notify({ title: 'Error', text: 'Room failed to create', icon: 'fas fa-server', variant: 'danger'});
        }
      },
      async update() {
        try {
          const input = {
            id: this.room.id,
            /*floor: this.room.floor,*/
            //floor: Number(String(this.room.number)[0]),
            number: this.room.number,
            beds: this.room.beds,
          }

          //await API.graphql(graphqlOperation(createDistrict, { input: input }));
          await this.notify({ title: 'Success', text: 'Room was successfully updated', icon: 'fas fa-server', variant: 'success' });

          this.$emit('update', input)
          this.$root.$emit('bv::toggle::modal', this.id)
        }
        catch (error) {
          console.error(error)
          this.notify({ title: 'Error', text: 'Room failed to update', icon: 'fas fa-server', variant: 'danger'});
        }
      },

      clear() {
        this.room = {
          id: null,
          //floor: null,
          number: null,
          beds: null
        }
        this.$emit('clear')
      },
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
      },
    }
  }
</script>

<style scoped>

</style>

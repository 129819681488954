<template>
  <b-modal :id="id" :title="id" size="xl" lazy
           body-class="w-100" hide-header hide-footer @show="load">
    <template #default>
      <b-overlay :show="true" bg-color="white" opacity="1" style="min-height: 100px;">
        <template v-if="!loading">
          <div class="name-badge-container d-none d-print-grid">
            <slot name="albany"/>
            <slot name="conference"/>
            <slot name="blank"/>
          </div>
        </template>
        <template v-else>
          <b-card class="shadow-none m-0 p-0">
            <b-card-body>

            </b-card-body>
          </b-card>
        </template>
      </b-overlay>
    </template>
  </b-modal>
</template>

<script>
import vSelect from 'vue-select'
import PageLayout from '@/components/PageLayout.vue';
import notify from '@/mixins/notify.mixin';
import OverlayLoading from '@/components/OverlayLoading.vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import ApplicationDetails from '@/views/all-state/application/ApplicationDetails.vue';
import ApplicationStudent from '@/views/all-state/application/ApplicationStudent.vue';
import ApplicationQuestions from '@/views/all-state/application/ApplicationQuestions.vue';
import ApplicationGrading from '@/views/all-state/application/ApplicationGrading.vue';
import popper from '@/mixins/popper.mixin';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import TableRowOptions from '@/components/TableRowOptions.vue';
import settingsMixin from '@/mixins/settings.mixin';
import events from '@/mixins/event.mixin';
import styleMixin from '@/mixins/style.mixin';

export default {
  name: 'BadgePrintModal',
  components: {
    TableRowOptions,
    BCardActions,
    ApplicationGrading,
    ApplicationDetails,
    ApplicationStudent,
    ApplicationQuestions,
    OverlayLoading,
    PageLayout,
    vSelect,
    VuePerfectScrollbar
  },
  mixins: [notify, popper, settingsMixin, events, styleMixin],
  props: {
    id: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      includeAlbany: false,
      includeConference: false,
      includeBlank: false,
    }
  },
  mounted() {
    window.addEventListener('afterprint', this.handleAfterPrint);
  },
  beforeDestroy() {
    window.removeEventListener('afterprint', this.handleAfterPrint);
  },
  methods: {
    async refresh() {
      await this.listStudentEvents()
    },
    async load() {
      await this.$nextTick(() => {
        setTimeout(() => {
          window.print()
        }, 500);
      })
    },

    handleAfterPrint() {
      this.$emit('after-print')
      this.$bvModal.hide(this.id)
    },
  }
}
</script>

<style lang="scss">
@media print {
  #app {
    visibility: hidden;
    display: none;
  }

  .modal {
    padding-left: 0 !important;
    position: relative;

    .modal-dialog {
      width: 100vw;
      max-width: none;
      margin: 0;

      span[tabindex] {
        visibility: hidden;
        display: none;
      }

      .modal-content {
        height: 100%;
        border: 0;

        .modal-body {
          overflow-y: visible;

          .b-overlay-wrap {
            overflow: hidden;

            .b-overlay {
              visibility: hidden;
              display: none;
            }
          }
        }
      }

      .modal-footer {
        visibility: hidden;
        display: none;
      }
    }
  }

  .modal-backdrop {
    visibility: hidden;
    display: none;
  }
}

@media print {
  .name-badge-container {
    background-color: #fff;
    width: 8.5in!important;
    height: 11in!important;
    display: grid;
    grid-template-columns: repeat(2, 4in);
    grid-template-rows: repeat(3, 3in);
    grid-column-gap: 0.1667in;
    grid-row-gap: 0.1667in;
    padding: 0.1667in;
    margin: 0;
    box-sizing: border-box;
    border-radius: 0.428rem;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    transform: none;
    transform-origin: initial;
    -webkit-transform-origin: initial;

    .name-badge {
      border: 0!important;
    }
  }

  .name-badge-container:not(:first-child) {
    margin-top: 2rem
  }
}

/* Hide everything outside of the container when printing */
@media print {
  @page {
    size: letter portrait!important;
    margin: 0;
  }

  body {
    margin: 0;
    padding: 0;
    min-width: unset !important;
  }

  .content-header {
    display: none;
  }

  body *:not(.name-badge-container, .name-badge-container *) {
    visibility: hidden;
    padding: 0!important;
    margin: 0!important;
    width: unset!important;
    min-width: unset!important;
    max-width: unset!important;
    border: 0;
  }
  .name-badge-container, .name-badge-container * {
    visibility: visible;
    box-shadow: unset;
    border: 0;
  }

  .name-badge-container {
    margin: 0!important;
  }
}


</style>



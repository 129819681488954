// eslint-disable-next-line import/prefer-default-export
export const listStudents = /* GraphQL */ `
    query ListStudents(
        $id: ID
        $filter: ModelStudentFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listStudents(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name {
                    first
                    last
                }
                grade
                dob
                phone {
                    number
                }
                school {
                    name {
                        legal
                    }
                }
                
            }
            nextToken
        }
    }
`;

export const listSelections = /* GraphQL */ `
    query ListSelections(
        $id: ID
        $filter: ModelSelectionFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSelections(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                part
                ensemble {
                    id
                    name
                }
                accepted
                application {
                    id
                    instrument {
                        id
                        name
                    }
                    festival {
                        name
                        zone {
                            name
                        }
                    }
                    student {
                        id
                        name {
                            first
                            last
                        }
                        dob
                        gender
                        sex
                        grade
                        phone {
                            number
                        }
                        email {
                            address
                        }
                        school {
                            name {
                                legal
                            }
                        }
                        
                        address {
                            line1
                            line2
                            city
                            state
                            zip
                            county
                        }
                    }
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const listBuses = /* GraphQL */ `
    query ListBuses(
        $id: ID
        $filter: ModelBusFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listBuses(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                __typename
            }
            nextToken
            __typename
        }
    }
`;
